export const getSunyDmcAffiliationTypes = () => {
	return ["Faculty & Staff", "Student", "Resident Physician", "Other"];
};

export const getSunyDmcAffiliationCategories = () => {
	return ["State", "Research Foundation", "UPB", "Other"];
};

export const getSunyDmcStateEntities = () => {
	return ["Hospital", "School", "Both"];
};

export const getSunyDmcStateUnion = () => {
	return ["MC", "UUP", "CSEA", "PEF", "PBANYS", "CWA-GSEU", "OTHER"];
};

export const getColors = () => {
	return ["Other", "Black", "Blue", "Brown", "Gold", "Gray", "Green", "Red", "Silver", "Tan", "White", "Yellow"];
};

export const getMakes = () => {
	return [
		"Other",
		"Acura",
		"Audi",
		"BMW",
		"Buick",
		"Cadillac",
		"Chevrolet",
		"Chrysler",
		"Daewoo",
		"Dodge",
		"Fiat",
		"Ford",
		"Genesis",
		"Geo",
		"GMC",
		"Honda",
		"Hummer",
		"Hyundai",
		"Infiniti",
		"Isuzu",
		"Jaguar",
		"Jeep",
		"Kia",
		"Landrover",
		"Lexus",
		"Lincoln",
		"Maserati",
		"Mazda",
		"Mercedes",
		"Mercury",
		"Mini",
		"Mitsubishi",
		"Nissan",
		"Oldsmobile",
		"Plymouth",
		"Pontiac",
		"Porsche",
		"Rangerover",
		"Saab",
		"Saturn",
		"Scion",
		"Smartcar",
		"Subaru",
		"Suzuki",
		"Tesla",
		"Toyota",
		"Volkswagen",
		"Volvo",
	];
};

export const getStates = () => {
	return [
		"AL",
		"AK",
		"AZ",
		"AR",
		"CA",
		"CO",
		"CT",
		"DE",
		"DC",
		"FL",
		"GA",
		"HI",
		"ID",
		"IL",
		"IN",
		"IA",
		"KS",
		"KY",
		"LA",
		"ME",
		"MD",
		"MA",
		"MI",
		"MN",
		"MS",
		"MO",
		"MT",
		"NE",
		"NV",
		"NH",
		"NJ",
		"NM",
		"NY",
		"NC",
		"ND",
		"OH",
		"OK",
		"OR",
		"PA",
		"RI",
		"SC",
		"SD",
		"TN",
		"TX",
		"UT",
		"VT",
		"VA",
		"WA",
		"WV",
		"WI",
		"WY",
		"N/A",
	];
};

export const getSunyDmcDepartments = () => {
	return [
		"ACADEMIC AFFAIRS",
		"ACADEMIC COMPUTING AND TECHNOLOGY",
		"ACADEMIC DEVELOPMENT-COLLEGE OF MEDICINE",
		"ACCOUNTS PAYABLE",
		"ADMINISTRATIVE SERVICES",
		"ADMISSIONS, STUDENT",
		"ADMITTING, HOSPITAL",
		"AFFILIATIONS, OFFICE OF",
		"ALUMNI ASSOCIATION, COLLEGE OF MEDICINE",
		"AMBULATORY CARE - OPD",
		"AMBULATORY SURGERY",
		"ANATOMY DEPARTMENT",
		"ANESTHESIOLOGY",
		"ARTHUR ASHE INSTITUTE FOR URBAN HEALTH",
		"BAY RIDGE, SUNY DOWNSTATE AT",
		"BIOCHEMISTRY DEPARTMENT",
		"BIOTECHNOLOGY INCUBATOR",
		"BLOOD BANK",
		"BOOKSTORE",
		"BUDGET PLANNING AND REPORTING",
		"BURSAR OFFICE",
		"CAFE 101",
		"CARDIAC CATHETERIZATION LABORATORIES",
		"CARDIOLOGY",
		"CASE MANAGEMENT",
		"CELL BIOLOGY",
		"CENTER FOR COMMUNITY HEALTH AND WELLNESS",
		"CENTER FOR HUMAN SEXUALITY",
		"CENTRAL RECEIVING",
		"CENTRAL STERILE SUPPLY",
		"CENTRAL STORES",
		"CHIEF ADMINISTRATION OFFICE",
		"CHILD LIFE PROGRAM",
		"CHILDRENS CENTER AT SUNY BROOKLYN, Inc",
		"CLASSROOM SERVICES",
		"CLINICAL LABS",
		"COLLEGE OF MEDICINE",
		"COLLEGE OF NURSING",
		"COMMUNICATIONS AND MARKETING",
		"COMMUNITY AND GOVERNMENTAL RELATIONS",
		"COMPARATIVE MEDICINE (DCM,DLAR)",
		"COMPLIANCE AND AUDIT SERVICES",
		"CONTINUING MEDICAL EDUCATION (CME)",
		"CONTRACTS OFFICE",
		"CONTROLLERS OFFICE",
		"COUNSEL, OFFICE OF UNIVERSITY",
		"CSEA OFFICE",
		"DEAN'S OFFICE- COM",
		"DEAN'S OFFICE- SPH",
		"DERMATOLOGY",
		"DIAGNOSTIC MEDICAL IMAGING PROGRAM-SoHP",
		"DIALYSIS CENTER, AMBULATORY",
		"DIALYSIS, INPATIENT",
		"DIETARY OFFICE",
		"DISCHARGE PLANNING",
		"DIVERSITY AND INCLUSION, OFFICE OF",
		"DOWNSTATE SURGICAL BILLING",
		"EMERGENCY DEPARTMENT (ED)",
		"EMERGENCY MEDICINE",
		"EMERGENCY PREPAREDNESS",
		"EMPLOYEE ASSISTANCE PROGRAM",
		"EMPLOYEE HEALTH SERVICES",
		"ENDOSCOPY",
		"ENVIRONMENTAL HEALTH AND SAFETY (FM&D)",
		"ENVIRONMENTAL SERVICES, HOSPITAL",
		"EPIDEMIOLOGY DEPARTMENT",
		"FACULTY STUDENT ASSOCIATION",
		"FAMILY HEALTH SERVICES",
		"FAMILY PRACTICE, DEPT OF",
		"FINANCE",
		"FINANCIAL AID, STUDENT",
		"FINANCIAL MANAGEMENT",
		"FM&D-FACILITIES MANAGEMENT AND DEVELOPMENT",
		"FOOD SERVICE, HOSPITAL",
		"GENOMIC HEALTH, INSTITUTE FOR",
		"GIFT SHOP",
		"GRADUATE MEDICAL EDUCATION (GME)",
		"GRADUATE STUDIES, SCHOOL OF",
		"HEALTH INFORMATION MGMT (HIM,MEDICAL RECORDS)",
		"HEALTH PROFESSIONS, SCHOOL OF (SoHP)",
		"HEALTH PROFESSIONS, SCHOOL OF (SoHP)-DMI",
		"HEALTH PROFESSIONS, SCHOOL OF (SoHP)-Med Informatics",
		"HEALTH PROFESSIONS, SCHOOL OF (SoHP)-MIDWIFERY",
		"HEALTH PROFESSIONS, SCHOOL OF (SoHP)-OT",
		"HEALTH PROFESSIONS, SCHOOL OF (SoHP)-PA",
		"HEALTH PROFESSIONS, SCHOOL OF (SoHP)-PT",
		"HEALTH SCI CTR AT BKLYN FOUNDATION, Inc.",
		"HEAT (HEALTH EDUC ALTERNATIVES FOR TEENS)",
		"HOSPITAL ADMINISTRATION",
		"HOSPITAL BUSINESS OFFICE",
		"HOSPITAL FINANCE",
		"HUMAN RESOURCES ",
		"HUMAN RESOURCES-BENEFITS",
		"HUMAN RESOURCES-LABOR RELATIONS",
		"HUMANITIES IN MEDICINE",
		"INFECTION CONTROL PROGRAM",
		"INFECTIOUS DISEASE",
		"INFORMATION SERVICES",
		"INFORMATION SERVICES-NETWORK TECHNOLOGY",
		"INSTITUTIONAL REVIEW BOARD",
		"INTERNAL MEDICINE",
		"LIBRARY",
		"LINEN",
		"MAIL SERVICES",
		"MANAGED CARE",
		"MANAGEMENT SYSTEMS",
		"MEDICAID OFFICE",
		"MEDICAL BOARD OFFICE",
		"MEDICAL ETHICS AND HUMANITIES",
		"MEDICINE, DEPARTMENT OF",
		"METS (MESSENGER/ESCORT/TRANSPORT SVC)",
		"MICROBIOLOGY AND IMMUNOLOGY",
		"MRI",
		"NEPHROLOGY",
		"NEUROLOGY DEPARTMENT",
		"NEUROSURGERY DEPARTMENT",
		"NUCLEAR MEDICINE",
		"NURSING RECRUITMENT AND RETENTION",
		"NURSING SERVICES",
		"OBSTETRICS AND GYNECOLOGY",
		"OPHTHALMOLOGY",
		"ORTHOPAEDIC SURGERY AND REHABILITATION MEDICINE",
		"OTOLARYNGOLOGY",
		"OUTPATIENT DIVISION (OPD), ADMIN",
		"PARENTING EDUCATION PROG./TEEN PARENT PROGRAM",
		"PARENTS ASSOCIATION (COLLEGE OF MEDICINE)",
		"PARKING",
		"PATHOLOGY",
		"PATIENT ACCOUNTS/BILLING",
		"PATIENT EDUCATION",
		"PATIENT RELATIONS/GUEST SERVICES",
		"PATIENT SAFETY",
		"PAYROLL",
		"PEDIATRICS",
		"PEF OFFICE",
		"PERIOPERATIVE SERVICES (O.R.)",
		"PHARMACOLOGY",
		"PHARMACY, INPATIENT",
		"PHARMACY, OUTPATIENT",
		"PHILANTHROPY",
		"PHYSIOLOGY",
		"PLANNING",
		"PRESIDENT'S OFFICE",
		"PROCUREMENT SERVICES",
		"PROPERTY CONTROL",
		"PSYCHIATRY",
		"PUBLIC HEALTH, SCHOOL OF (SPH)",
		"QUALITY MANAGEMENT",
		"QUICK COPY CENTER",
		"RADIATION ONCOLOGY",
		"RADIATION SAFETY",
		"RADIOLOGY",
		"REAL ESTATE",
		"RECOVERY ROOM",
		"REGISTRAR, STUDENT",
		"REGULATORY AFFAIRS, HOSPITAL",
		"RESEARCH ADMINISTRATION",
		"RESIDENTIAL LIFE AND SERVICES",
		"RESPIRATORY CARE",
		"RISK MANAGEMENT",
		"SCIENCE AND TECHNOLOGY ENTRY PROGRAM (STEP)",
		"SCIENTIFIC & MEDICAL INSTRUMENTATION CENTER (SMIC)",
		"SCIENTIFIC AFFAIRS",
		"SOCIAL WORK",
		"SPEECH PATHOLOGY SVC.",
		"SPIRITUAL CARE",
		"STAR (SPECIAL TREATMENT AND RESEARCH)",
		"STUDENT AFFAIRS -ADMINISTRATION",
		"STUDENT AFFAIRS -FINANCIAL AID",
		"STUDENT CENTER",
		"STUDENT HEALTH SERVICE",
		"STUDENT LIFE",
		"SURGERY DEPARTMENT",
		"SURGERY, AMBULATORY",
		"SURGICAL PATHOLOGY",
		"TELECOMMUNICATIONS",
		"TELEPHONE SWITCHBOARD OPERATIONS",
		"TRANSPLANT SURGERY",
		"TUMOR REGISTRY OFFICE",
		"UNIVERSITY PHYSICIANS OF BKLYN, INC. (UPB)",
		"UNIVERSITY POLICE",
		"UNIVERSITY POLICE-TRANSPORTATION",
		"UROLOGY",
		"UTILIZATION REVIEW",
		"UUP OFFICE",
		"VOLUNTEER SERVICES",
		"OTHER",
	];
};
