import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import StatusOkImage from '../../../resources/images/status/status-ok.png';
import StatusErrorImage from '../../../resources/images/status/status-error.png';
import '../Message.scss';

const ConfirmationMessage = ({ messageHeader, messageBody, status }) => {
	return (
		<div className='message-container'>
			{status && status === 'ok' && <img src={StatusOkImage} alt={'green tick'} />}
			{status && status === 'error' && <img src={StatusErrorImage} alt={'red cross'} />}
			<h2 className='message-container__header'> {messageHeader} </h2>
			<p className='message-container__body'>{messageBody}</p>
			<Button className='message-container__go-home' component={NavLink} variant='contained' to='/spotshark '>
				Go to homepage
			</Button>
		</div>
	);
};

export default ConfirmationMessage;
