import { DateTime } from "luxon";

export const isEmpty = (value) => {
	return !value || value === "";
};

export const isEmptyObject = (object) => {
	return Object.keys(object).length === 0 && object.constructor === Object;
}

export const isMatchingPattern = (regexp, value) => {
	return new RegExp(regexp).test(value);
};

export const equalsIgnoreCase = (string1, string2) => {
	return string1 != null && string2 != null && string1.toUpperCase() === string2.toUpperCase();
};

export const getStateAbbreviations = () => {
	return ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"];
}

export const getAmericanDateFormatNoYear = (time) => {
	//in second or millis
	const date = DateTime.fromMillis(time * (time.toString().length === 13 ? 1 : 1000));
	const hour = date.hour > 12 ? date.hour - 12 : date.hour;
	return `${padTo2Digits(date.month)}/${padTo2Digits(date.day)} ${padTo2Digits(hour)}:${padTo2Digits(date.minute)} ${date.hour >= 12 ? "PM" : "AM"}`;
};

export const getAmericanDateFormat = (time) => {
	//in second or millis
	const date = DateTime.fromMillis(time * (time.toString().length === 13 ? 1 : 1000));
	const hour = date.hour > 12 ? date.hour - 12 : date.hour;
	return `${padTo2Digits(date.month)}/${padTo2Digits(date.day)}/${date.year.toString().substr(-2)} ${padTo2Digits(hour)}:${padTo2Digits(date.minute)} ${date.hour >= 12 ? "PM" : "AM"}`;
};

export const convertMilisToHoursAndMinutes = (milliseconds, showZeroMinutes= false) => {
	if (showZeroMinutes) {
		return "0m";
	}
	let seconds = Math.floor(milliseconds / 1000);
	let minutes = Math.floor(seconds / 60);
	let hours = Math.floor(minutes / 60);
	let days = Math.floor(hours / 24);

	// if seconds are greater than 0, round minutes up (optional) - we dont want to round up rn, gn is skippinig seconds so we are
	//seconds = seconds % 60;
	//minutes = seconds >= 0 ? minutes + 1 : minutes;
	minutes = minutes % 60;
	// If you don't want to roll hours over, e.g. 24 to 00
	// comment (or remove) the line below
	// commenting next line gets you `24:00:00` instead of `00:00:00`
	// or `36:15:31` instead of `12:15:31`, etc.
	hours = hours % 24;

	const daysPart = days > 0 ? days + "d " : "";
	const hoursPart = hours > 0 ? hours + "h " : "";
	const minutesPart = minutes > 0 ? minutes + "m " : days === 0 && hours === 0 ? "1m" : "";
	return `${daysPart}${hoursPart}${minutesPart}`.trim();
};

const padTo2Digits = (num) => {
	return num.toString().padStart(2, "0");
};
