import {Close, DirectionsCar} from '@mui/icons-material';
import {IconButton, InputAdornment, MenuItem, TextField} from '@mui/material';
import {Field} from 'formik';
import React from 'react';
import {getColors, getMakes, getStates} from '../../util/data';
import './Form.scss';

const VehiclePart = ({vehicle_number, display, close}) => {
  const class_name = 'vehicle' + vehicle_number;
  const lp_field_class_name = class_name + '_lp';
  const color_field_class_name = class_name + '_color';
  const make_field_class_name = class_name + '_make';
  const state_field_class_name = class_name + '_state';

  return (
    <div className={'vehicle-part'} hidden={display === false}>
      <div>
        {vehicle_number <= 1 ? null : (
          <IconButton className={'vehicle-close'} onClick={close}>
            <Close/>
          </IconButton>
        )}
        <h3 style={{marginLeft: vehicle_number !== 1 ? '25px' : '0'}}>Vehicle #{vehicle_number}</h3>
      </div>
      <Field className='form-form__form__downst-license-plate' name={lp_field_class_name}>
        {({field, form, meta}) => (
          <>
            <TextField
              required
              label='License plate'
              name={lp_field_class_name}
              size={'small'}
              inputProps={{maxLength: 10}}
              error={Boolean(form.errors[lp_field_class_name] && form.touched[lp_field_class_name])}
              helperText={form.errors[lp_field_class_name] && form.touched[lp_field_class_name] && String(form.errors[lp_field_class_name])}
              fullWidth
              InputLabelProps={{required: false, htmlFor: `${lp_field_class_name}`}}
              InputProps={{
                id: `${lp_field_class_name}`,
                startAdornment: (
                  <InputAdornment position='start'>
                    <DirectionsCar/>
                  </InputAdornment>
                ),
              }}
              {...field}
            />
          </>
        )}
      </Field>
      <Field className='form-form__form__downst-color' name={color_field_class_name}>
        {({field, form, meta}) => (
          <>
            <TextField
              required
              label='Color'
              name={color_field_class_name}
              defaultValue={''}
              size={'small'}
              error={Boolean(form.errors[color_field_class_name] && form.touched[color_field_class_name])}
              helperText={form.errors[color_field_class_name] && form.touched[color_field_class_name] && String(form.errors[color_field_class_name])}
              fullWidth
              InputLabelProps={{required: false, shrink: true, htmlFor: `${color_field_class_name}`}}
              InputProps={{
                id: `${color_field_class_name}`,
              }}
              select
              {...field}
            >
              <MenuItem value=''> -- Select a color --</MenuItem>
              {getColors().map((d) => (
                <MenuItem value={d} key={d}>
                  {d}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
      </Field>
      <Field className='form-form__form__make' name={make_field_class_name}>
        {({field, form, meta}) => (
          <>
            <TextField
              required
              label='Make'
              name={make_field_class_name}
              defaultValue={''}
              size={'small'}
              error={Boolean(form.errors[make_field_class_name] && form.touched[make_field_class_name])}
              helperText={form.errors[make_field_class_name] && form.touched[make_field_class_name] && String(form.errors[make_field_class_name])}
              fullWidth
              InputLabelProps={{required: false, shrink: true, htmlFor: `${make_field_class_name}`}}
              InputProps={{
                id: `${make_field_class_name}`,
              }}
              select
              {...field}
            >
              <MenuItem value=''> -- Select a make --</MenuItem>
              {getMakes().map((d) => (
                <MenuItem value={d} key={d}>
                  {d}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
      </Field>
      <Field className='form-form__form__state' name={state_field_class_name}>
        {({field, form, meta}) => (
          <>
            <TextField
              required
              label='State'
              name={state_field_class_name}
              defaultValue={''}
              size={'small'}
              error={Boolean(form.errors[state_field_class_name] && form.touched[state_field_class_name])}
              helperText={form.errors[state_field_class_name] && form.touched[state_field_class_name] && String(form.errors[state_field_class_name])}
              fullWidth
              InputLabelProps={{required: false, shrink: true, htmlFor: `${state_field_class_name}`}}
              InputProps={{
                id: `${state_field_class_name}`,
              }}
              select
              {...field}
            >
              <MenuItem value=''> -- Select a state --</MenuItem>
              {getStates().map((d) => (
                <MenuItem value={d} key={d}>
                  {d}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
      </Field>
    </div>
  );
};

export default VehiclePart;
