import loadable from '@loadable/component';
import React, {createContext, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import MonthlySignup from './pages/monthlysignup/MonthlySignup';

const Monarch = loadable(() => import(/*webpackChunkName: "Monarch"*/ './pages/monthlysignup/monarch/Monarch'));
const CouponsScan = loadable(() => import(/*webpackChunkName: "CouponsScan"*/ "./pages/coupons/CouponsScan"));
const SsqrScan = loadable(() => import(/*webpackChunkName: "SsqrScan"*/ "./pages/payments/ssqrscan/SsqrScan"));
const RegistrationRequest = loadable(() => import(/*webpackChunkName: "SsqrScan"*/ "./pages/registrationlink/RegistrationRequest"));
const FpPaymentReturn = loadable(/*webpackChunkName: "FpPaymentReturn"*/ () => import("./pages/payments/fpFrame/FpPaymentReturn"));
const SunyDmc = loadable(() => import(/*webpackChunkName: "SunyDmc"*/ "./pages/monthlysignup/sunydmc/SunyDmc"));
const PlateLookup = loadable(/*webpackChunkName: "PlateLookup"*/ () => import("./pages/platelookup/PlateLookup"));
const SpotsharkAccountUnsubscribe = loadable(/*webpackChunkName: "SpotsharkAccountUnsubscribe"*/ () => import("./pages/spotsharkaccount/SpotsharkAccountUnsubscribe"));
const SpotsharkAccountConfirmation = loadable(/*webpackChunkName: "SpotsharkAccountConfirmation"*/ () => import("./pages/spotsharkaccount/SpotsharkAccountConfirmation"));
const Welcome = loadable(() => import(/*webpackChunkName: "Welcome"*/ "./pages/welcome/Welcome"));
const Bot = loadable(() => import(/*webpackChunkName: "Welcome"*/ "./pages/welcome/Bot"));
const TicketScan = loadable(() => import(/*webpackChunkName: "TicketScan"*/ "./pages/ticketscan/TicketScan"));
const TermsOfUse = loadable(() => import(/*webpackChunkName: "TermsOfUse"*/ "./resources/Spotshark_TOS"));
const PrivacyPolicy = loadable(() => import(/*webpackChunkName: "PrivacyPolicy"*/ "./resources/Spotshark_privacy_policy"));
const RetrieveLostEticket = loadable(() => import(/*webpackChunkName: "RetrieveLostEticket"*/ "./pages/spotsharkaccount/RetrieveLostEticket"));
const CapacityPage = loadable(() => import(/*webpackChunkName: "CapacityPage"*/ "./pages/capacity/CapacityPage"));

export const AppContext = createContext();

const AppRouter = () => {
  const [appContext, setAppContext] = useState({unhideTicketDetails: false});

	return (
		<AppContext.Provider value={{ appContext, setAppContext }}>
			<BrowserRouter>
				<Routes>
					<Route path="/spotshark" element={<Welcome />} />
					<Route path="/bot" element={<Bot />} />
					<Route path="/ticket-scan" element={<TicketScan />} />
					<Route path="/register/:locationCode/:version/:encryptedData" element={<RegistrationRequest />} />
					<Route path="/a/:locationCode/:version/:encryptedData" element={<SsqrScan />} />
					<Route path="/:locationCode" element={<PlateLookup />} />
					<Route path="/l/sunydmc/signup" element={<SunyDmc />} />
					<Route path='/l/monarch/signup' element={<Monarch/>}/>
					<Route path="/paymentFP-return" element={<FpPaymentReturn />} />
					<Route path="/paymentFP-return-HPP" element={<FpPaymentReturn />} />{/*old*/}
					<Route path="/Spotshark_privacy_policy" element={<PrivacyPolicy />} />
					<Route path="/Spotshark_TOS" element={<TermsOfUse />} />
					<Route path="/customer-confirmation/n/:confirmationData/l/:locationCode" element={<SpotsharkAccountConfirmation />} />
					<Route path="/customer-confirmation/c/:confirmationData/l/:companyAlias" element={<CapacityPage />} />
					<Route path="/customer-confirmation/c/:confirmationData/l/:companyAlias/:garageAlias" element={<CapacityPage />} />
					<Route path="/unsubscribe" element={<SpotsharkAccountUnsubscribe />} /> {/*old*/}
					<Route path="/spotshark-account-unsubscribe" element={<SpotsharkAccountUnsubscribe />} />
					<Route path="/retrieve-lost-eticket" element={<RetrieveLostEticket />} />
					<Route path="/d/:locationCode/:encryptedData" element={<CouponsScan />} />
					<Route path="/capacity/:companyAlias" element={<CapacityPage />} />
					<Route path="/capacity/:companyAlias/:garageAlias" element={<CapacityPage />} />
					<Route path="*" element={<Welcome />} />
				</Routes>
			</BrowserRouter>
		</AppContext.Provider>
	);
};

export default AppRouter;
