import {Accessible, AccountCircle, Apartment, Badge, Email, Groups, Phone, PhoneIphone} from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  TextField
} from '@mui/material';
import {Field, Form, Formik} from 'formik';
import React, {useRef, useState} from 'react';
import DownstateCardExample from '../../resources/images/downstate-card.jpg';
import PayrollExample from '../../resources/images/downstate-payroll.jpg';
import {
  getSunyDmcAffiliationCategories,
  getSunyDmcAffiliationTypes,
  getSunyDmcDepartments,
  getSunyDmcStateEntities,
  getSunyDmcStateUnion
} from '../../util/data';
import {equalsIgnoreCase, isEmpty, isMatchingPattern} from '../../util/util';
import './Form.scss';
import VehiclePart from './VehiclePart';

const SignupForm = ({setSignupResult, displayMessage, data}) => {
  console.log('🚀 ~ SignupForm ~ data:', data);
  const formRef = useRef();
  const [acceptedToc, setAcceptedToc] = useState(null);
  const [numberOfCars, setNumberOfCars] = useState(1);
  const [currentVehiclesIncomplete, setCurrentVehiclesIncomplete] = useState(true);
  const [payrollExampleOpen, setPayrollExampleOpen] = React.useState(false);
  const [downstateIdExampleOpen, setDownstateIdExampleOpen] = React.useState(false);
  const maxNumberOfCars = 3;
  const handleNextVehicleClick = () => {
    if (numberOfCars < maxNumberOfCars) {
      if (currentVehiclesIncomplete) {
        displayMessage('Please fill current vehicle first', 'info');
      } else {
        setNumberOfCars(numberOfCars + 1);
        setCurrentVehiclesIncomplete(true);
      }
    } else {
      displayMessage("You can't add more than " + maxNumberOfCars + ' vehicles', 'info');
    }
  };
  const handleRemoveVehicleClick = () => {
    if (numberOfCars > 1) {
      setNumberOfCars(numberOfCars - 1);
      setCurrentVehiclesIncomplete(false);
    } else {
      displayMessage("You can't remove all vehicles", 'info');
    }
  };
  const handleSubmitClick = () => {
    if (data.company_name === 'suny' && acceptedToc !== true) {
      setAcceptedToc(false);
    } else {
      formRef.current.submitForm().then(() => {
        console.log(formRef.current?.errors);
        if (Object.keys(formRef.current?.errors).length === 0) {
          signup(formRef.current.values);
        } else {
          const firstInvalidInput = document.querySelector(`input[name=${Object.keys(formRef.current?.errors)[0]}]`);
          firstInvalidInput.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'});
        }
      });
    }
  };
  const transformFormValuesToZohoAccountModel = (form_values) => {
    const type = equalsIgnoreCase(form_values.affiliation_type, 'other') ? form_values.other_affiliation_type : form_values.affiliation_type;
    const category = equalsIgnoreCase(form_values.affiliation_type, 'faculty & staff')
      ? equalsIgnoreCase(form_values.affiliation_category, 'other')
        ? form_values.affiliation_other_category
        : form_values.affiliation_category
      : '';
    const entity = equalsIgnoreCase(form_values.affiliation_type, 'faculty & staff') && equalsIgnoreCase(form_values.affiliation_category, 'state') ? form_values.entity : '';
    const nys_employee_id = equalsIgnoreCase(form_values.affiliation_type, 'faculty & staff') && equalsIgnoreCase(form_values.affiliation_category, 'state') ? form_values.nys_employee_id : '';
    const union =
      equalsIgnoreCase(form_values.affiliation_type, 'faculty & staff') && equalsIgnoreCase(form_values.affiliation_category, 'state')
        ? equalsIgnoreCase(form_values.union, 'other')
          ? form_values.other_union
          : form_values.union
        : '';
    const affiliation = `${type}#${category}#${entity}#${nys_employee_id}#${union}`;
    const custom_fields = data.company_name !== 'suny'
      ? []
      : [
        {
          value: form_values.handicapped_placard,
          label: 'Handicapped Placard #',
        },
        {
          value: affiliation,
          label: 'Affiliation#Category#Entity#NYS_Payroll#Union',
        },
        {
          value: form_values.downst_card_id,
          label: 'Downstate Id Card #',
        },
        {
          value: form_values.department,
          label: 'Department',
        },
        form_values.department.toLowerCase() === 'other'
          ? {
            value: form_values.other_department,
            label: 'Other Department',
          }
          : null,
      ].filter((item) => item !== null);
    for (let i = 1; i <= numberOfCars; i++) {
      const vehicle = 'vehicle' + i;
      if (
        !isEmpty(form_values[vehicle + '_lp']) &&
        isMatchingPattern(/^[a-zA-Z0-9- ]{5,9}$/, form_values[vehicle + '_lp']) &&
        !isEmpty(form_values[vehicle + '_color']) &&
        !isEmpty(form_values[vehicle + '_make']) &&
        !isEmpty(form_values[vehicle + '_state'])
      ) {
        custom_fields.push({
          value: form_values[vehicle + '_make'],
          label: `Vehicle#${i} Make`,
        });
        custom_fields.push({
          value: form_values[vehicle + '_color'],
          label: `Vehicle#${i} Color`,
        });
        custom_fields.push({
          value: form_values[vehicle + '_lp'].replaceAll(/[- ]/g, '').toUpperCase(),
          label: `Vehicle#${i} License Plate`,
        });
        custom_fields.push({
          value: form_values[vehicle + '_state'],
          label: `Vehicle#${i} State`,
        });
      }
    }
    return {
      company_name: data.company_name,
      display_name: form_values.last_name + ', ' + form_values.first_name,
      first_name: form_values.first_name,
      last_name: form_values.last_name,
      email: form_values.email,
      phone: form_values.phone,
      mobile: form_values.mobile,
      currency_code: 'USD',
      ach_supported: true,
      notes: form_values.note,
      is_portal_enabled: true,
      custom_fields: custom_fields,
    };
  };
  const signup = (form_values) => {
    console.log('Signup', form_values);
    fetch('/api/zoho-account?', {
      method: 'post',
      body: JSON.stringify(transformFormValuesToZohoAccountModel(form_values)),
      headers: {
        'content-type': 'application/json',
        'request-uri': window.location.href,
      },
    }).then((response) => {
      console.log('zoho signup response', response);
      if (response?.ok) {
        response.text().then((signupResult) => {
          setSignupResult(signupResult);
        });
      } else {
        response.text().then((errorMsg) => {
          console.log('zoho signup error message', errorMsg);
          errorMsg.includes('mail') ? setSignupResult('mail_error') : displayMessage('Something went wrong, try again or contact your administrator', 'error');
        });
      }
    });
  };
  return (
    <div className='form'>
      <h2>Register for parking</h2>
      <div className='form__form'>
        <Formik
          onSubmit={() => {
          }}
          innerRef={formRef}
          initialValues={{
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            mobile: '',
            note: '',
            downst_card_id: '',
            department: '',
            other_department: '',
            affiliation_type: '',
            other_affiliation_type: '',
            affiliation_category: '',
            affiliation_other_category: '',
            entity: '',
            union: '',
            other_union: '',
            nys_employee_id: '',
            handicapped_placard: '',
            vehicle1_lp: '',
            vehicle1_color: '',
            vehicle1_other_color: '',
            vehicle1_make: '',
            vehicle1_state: '',
            vehicle2_lp: '',
            vehicle2_color: '',
            vehicle2_other_color: '',
            vehicle2_make: '',
            vehicle2_state: '',
            vehicle3_lp: '',
            vehicle3_color: '',
            vehicle3_other_color: '',
            vehicle3_make: '',
            vehicle3_state: '',
          }}
          validate={(values) => {
            const errors = {};
            if (isEmpty(values.first_name)) {
              errors.first_name = 'This field is required. Please tell us your first name.';
            }
            if (isEmpty(values.last_name)) {
              errors.last_name = 'This field is required. Please tell us your last name.';
            }
            if (isEmpty(values.email) ||
              (data.company_name === 'suny' && !(isMatchingPattern(/@downstate.edu\s*$/, values.email.toLowerCase()) || isMatchingPattern(/@garagenet.com\s*$/, values.email.toLowerCase())))) {
              errors.email = 'This field is required. Please provide a valid email address within downstate.edu domain.';
            }
            if (isEmpty(values.phone) && isEmpty(values.mobile)) {
              errors.phone = 'One phone contact is needed. ';
              errors.mobile = 'One phone contact is needed. ';
            }
            const provideValidPhoneMessage = 'Please provide a valid phone number.';
            if (!isEmpty(values.phone) && !isMatchingPattern(/^[+]?[()0-9 -]+$/, values.phone)) {
              errors.phone = !errors.phone ? provideValidPhoneMessage : errors.phone + provideValidPhoneMessage;
            }
            if (!isEmpty(values.mobile) && !isMatchingPattern(/^[+]?[()0-9 -]+$/, values.mobile)) {
              errors.mobile = !errors.mobile ? provideValidPhoneMessage : errors.mobile + provideValidPhoneMessage;
            }
            if (data.company_name === 'suny' && (isEmpty(values.downst_card_id) || !isMatchingPattern(/^\d{6}$/, values.downst_card_id))) {
              errors.downst_card_id = 'This field is required. Please provide a six digits Downstаte Id Cаrd #.';
            }
            if (data.company_name === 'suny' && isEmpty(values.department)) {
              errors.department = 'This field is required. Please select your department.';
            } else if (equalsIgnoreCase(values.department, 'other') && isEmpty(values.other_department)) {
              errors.other_department = 'This field is required. Please provide your department.';
            }
            if (data.company_name === 'suny') {
              if (isEmpty(values.affiliation_type)) {
                errors.affiliation_type = 'This field is required. Please select your affiliation.';
              } else if (equalsIgnoreCase(values.affiliation_type, 'Other') && isEmpty(values.other_affiliation_type)) {
                errors.other_affiliation_type = 'This field is required. Please provide your affiliation.';
              } else if (equalsIgnoreCase(values.affiliation_type, 'Faculty & Staff')) {
                if (isEmpty(values.affiliation_category)) {
                  errors.affiliation_category = 'This field is required. Please select affiliation category.';
                } else if (equalsIgnoreCase(values.affiliation_category, 'Other') && isEmpty(values.affiliation_other_category)) {
                  errors.affiliation_other_category = 'This field is required. Please provide affiliation category.';
                } else if (equalsIgnoreCase(values.affiliation_category, 'State')) {
                  if (isEmpty(values.nys_employee_id) || !isMatchingPattern(/^N{1}\d{8}/, values.nys_employee_id)) {
                    errors.nys_employee_id = 'This field is required. Please provide a letter N followed by 8 digits NYS Payroll Employee ID #.';
                  }
                  /*if (isEmpty(values.union)) {
                                      errors.union = "This field is required. Please select union.";
                                  } else if (equalsIgnoreCase(values.union, "other") && isEmpty(values.other_union)) {
                                      errors.other_union = "This field is required. Please provide your union."
                                  }
                                  if (isEmpty(values.entity)) {
                                      errors.entity = "This field is required. Please select entity."
                                  }todo ss2-86 uncomment if needed*/
                }
              }
            }
            if (data.company_name === 'suny' && !isEmpty(values.handicapped_placard) && !isMatchingPattern(/^\d{5,7}$/, values.handicapped_placard)) {
              errors.handicapped_placard = 'This field can contain only 5 to 7 digits.';
            }
            setCurrentVehiclesIncomplete(false);
            for (let i = 1; i <= numberOfCars; i++) {
              const vehicle_class = 'vehicle' + i;
              if (isEmpty(values[vehicle_class + '_lp']) || !isMatchingPattern(/^[a-zA-Z0-9-]{5,9}$/, values[vehicle_class + '_lp'].replaceAll(/[ ]/g, ''))) {
                errors[vehicle_class + '_lp'] = 'This field is required. Please provide a valid License plate.';
                setCurrentVehiclesIncomplete(true);
              }
              if (isEmpty(values[vehicle_class + '_color'])) {
                errors[vehicle_class + '_color'] = 'This field is required. Please select your vehicle color.';
                setCurrentVehiclesIncomplete(true);
              } else if (equalsIgnoreCase(values[vehicle_class + '_color'], ' Other') && isEmpty(values[vehicle_class + '_other_color'])) {
                errors[vehicle_class + '_other_color'] = 'This field is required. Please specify your vehicle color.';
                setCurrentVehiclesIncomplete(true);
              }
              if (isEmpty(values[vehicle_class + '_make'])) {
                errors[vehicle_class + '_make'] = 'This field is required. Please select your vehicle make.';
                setCurrentVehiclesIncomplete(true);
              }
              if (isEmpty(values[vehicle_class + '_state'])) {
                errors[vehicle_class + '_state'] = 'This field is required. Please select your vehicle state.';
                setCurrentVehiclesIncomplete(true);
              }
            }
            if (data.company_name !== 'suny' || (values.accept_toc && values.accept_toc[0] === 'on')) {
              setAcceptedToc(true);
            }
            return errors;
          }}
        >
          <Form>
            <Field className='form-form__form__first-name' name='first_name'>
              {({field, form, meta}) => (
                <>
                  <TextField
                    label='First name'
                    name={'first_name'}
                    size={'small'}
                    error={Boolean(form.errors.first_name && form.touched.first_name)}
                    helperText={form.errors.first_name && form.touched.first_name && String(form.errors.first_name)}
                    fullWidth
                    InputLabelProps={{required: false, htmlFor: 'first_name'}}
                    inputProps={{
                      maxLength: 255,
                      autoComplete: 'first_name',
                      id: 'first_name',
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AccountCircle/>
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                </>
              )}
            </Field>
            <Field className='form-form__form__last-name' name='last_name'>
              {({field, form, meta}) => (
                <>
                  <TextField
                    label='Last name'
                    name={'last_name'}
                    size={'small'}
                    error={Boolean(form.errors.last_name && form.touched.last_name)}
                    helperText={form.errors.last_name && form.touched.last_name && String(form.errors.last_name)}
                    fullWidth
                    InputLabelProps={{required: false, htmlFor: 'last_name'}}
                    inputProps={{
                      maxLength: 255,
                      autoComplete: 'last_name',
                      id: 'last_name',
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <AccountCircle/>
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                </>
              )}
            </Field>
            <Field className='form-form__form__email' name='email'>
              {({field, form, meta}) => (
                <>
                  <TextField
                    label='Email'
                    name={'email'}
                    type={'email'}
                    size={'small'}
                    error={Boolean(form.errors.email && form.touched.email)}
                    helperText={form.errors.email && form.touched.email && String(form.errors.email)}
                    fullWidth
                    InputLabelProps={{required: false, htmlFor: 'email'}}
                    inputProps={{
                      maxLength: 255,
                      autoComplete: 'email',
                      id: 'email',
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Email/>
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                </>
              )}
            </Field>
            <p className={'hint'}>Example: yourname@email.edu</p>
            <Field className='form-form__form__phone' name='phone'>
              {({field, form, meta}) => (
                <>
                  <TextField
                    label='Phone'
                    name={'phone'}
                    size={'small'}
                    error={Boolean(form.errors.phone && form.touched.phone)}
                    helperText={form.errors.phone && form.touched.phone && String(form.errors.phone)}
                    fullWidth
                    InputLabelProps={{required: false, htmlFor: 'phone'}}
                    inputProps={{
                      maxLength: 20,
                      autoComplete: 'phone',
                      id: 'phone',
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Phone/>
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                </>
              )}
            </Field>
            <Field className='form-form__form__mobile' name='mobile'>
              {({field, form, meta}) => (
                <>
                  <TextField
                    label='Mobile phone'
                    name={'mobile'}
                    size={'small'}
                    error={Boolean(form.errors.mobile && form.touched.mobile)}
                    helperText={form.errors.mobile && form.touched.mobile && String(form.errors.mobile)}
                    fullWidth
                    InputLabelProps={{required: false, htmlFor: 'mobile'}}
                    inputProps={{
                      maxLength: 20,
                      autoComplete: 'mobile',
                      id: 'mobile',
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <PhoneIphone/>
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                  />
                </>
              )}
            </Field>
            <Field className='form-form__form__note' name='note'>
              {({field, form, meta}) => (
                <>
                  <TextField
                    label='Note'
                    name={'note'}
                    size={'small'}
                    multiline={true}
                    minRows={3}
                    error={Boolean(form.errors.note && form.touched.note)}
                    helperText={form.errors.note && form.touched.note && String(form.errors.note)}
                    fullWidth
                    inputProps={{maxLength: 300, autoComplete: 'note', id: 'note'}}
                    InputLabelProps={{required: false, shrink: true, htmlFor: 'note'}}
                    {...field}
                  />
                </>
              )}
            </Field>
            {data?.company_name === 'suny' && (
              <>
                <Field className='form-form__form__downst-card-id' name='downst_card_id'>
                  {({field, form, meta}) => (
                    <>
                      <TextField
                        label='Downstate ID card #'
                        name={'downst_card_id'}
                        size={'small'}
                        error={Boolean(form.errors.downst_card_id && form.touched.downst_card_id)}
                        helperText={form.errors.downst_card_id && form.touched.downst_card_id && String(form.errors.downst_card_id)}
                        fullWidth
                        InputLabelProps={{required: false, htmlFor: 'downst_card_id'}}
                        inputProps={{maxLength: 6, id: 'downst_card_id'}}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Badge/>
                            </InputAdornment>
                          ),
                        }}
                        {...field}
                      />
                    </>
                  )}
                </Field>
                <p className={'hint'}>
                  {/* eslint-disable jsx-a11y/anchor-is-valid */}
                  Last six digits from back of your Downstаte ID cаrd. <a
                  onClick={() => setDownstateIdExampleOpen(true)}>Show example</a>.
                </p>
                <Field className='form-form__form__department' name='department'>
                  {({field, form, meta}) => (
                    <>
                      <TextField
                        label='Department'
                        name='department'
                        size={'small'}
                        defaultValue={''}
                        error={Boolean(form.errors.department && form.touched.department)}
                        helperText={form.errors.department && form.touched.department && String(form.errors.department)}
                        InputLabelProps={{required: false, htmlFor: 'department'}}
                        InputProps={{
                          id: 'department',
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Apartment/>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        select
                        {...field}
                      >
                        <MenuItem value=''> -- Select a department --</MenuItem>
                        {getSunyDmcDepartments().map((d) => (
                          <MenuItem value={d} key={d}>
                            {d}
                          </MenuItem>
                        ))}
                      </TextField>
                    </>
                  )}
                </Field>
                <Field className='form-form__form__other-department' name='other_department'>
                  {({field, form, meta}) => (
                    <>
                      <TextField
                        label='Other department'
                        name='other_department'
                        size={'small'}
                        error={Boolean(form.errors.other_department && form.touched.other_department)}
                        helperText={form.errors.other_department && form.touched.other_department && String(form.errors.other_department)}
                        style={{display: equalsIgnoreCase(form.values.department, 'other') ? 'block' : 'none'}}
                        InputLabelProps={{required: false, htmlFor: 'other_department'}}
                        inputProps={{maxLength: 255, id: 'other_department'}}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Apartment/>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        {...field}
                      />
                    </>
                  )}
                </Field>
                <Field className='form-form__form__affiliation-type' name='affiliation_type'>
                  {({field, form, meta}) => (
                    <>
                      <TextField
                        label='Affiliation'
                        name='affiliation_type'
                        defaultValue={''}
                        size={'small'}
                        error={Boolean(form.errors.affiliation_type && form.touched.affiliation_type)}
                        helperText={form.errors.affiliation_type && form.touched.affiliation_type && String(form.errors.affiliation_type)}
                        fullWidth
                        InputLabelProps={{required: false, htmlFor: 'affiliation_type'}}
                        InputProps={{
                          id: 'affiliation_type',
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Groups/>
                            </InputAdornment>
                          ),
                        }}
                        select
                        {...field}
                      >
                        <MenuItem value=''> -- Select an affiliation -- </MenuItem>
                        {getSunyDmcAffiliationTypes().map((d) => (
                          <MenuItem value={d} key={d}>
                            {d}
                          </MenuItem>
                        ))}
                      </TextField>
                    </>
                  )}
                </Field>
                <Field className='form-form__form__other-affiliation-type' name='other_affiliation_type'>
                  {({field, form, meta}) => (
                    <>
                      <TextField
                        label='Other affiliation'
                        name='other_affiliation_type'
                        size={'small'}
                        error={Boolean(form.errors.other_affiliation_type && form.touched.other_affiliation_type)}
                        helperText={form.errors.other_affiliation_type && form.touched.other_affiliation_type && String(form.errors.other_affiliation_type)}
                        style={{display: equalsIgnoreCase(form.values.affiliation_type, 'other') ? 'block' : 'none'}}
                        InputLabelProps={{required: false, htmlFor: 'other_affiliation_type'}}
                        inputProps={{maxLength: 255, id: 'other_affiliation_type'}}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Apartment/>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        {...field}
                      />
                    </>
                  )}
                </Field>
                <Field className='form-form__form__affiliation-category' name='affiliation_category'>
                  {({field, form, meta}) => (
                    <>
                      <TextField
                        label='Affiliation category'
                        name='affiliation_category'
                        className={'affiliation-category-input'}
                        defaultValue={''}
                        size={'small'}
                        error={Boolean(form.errors.affiliation_category && form.touched.affiliation_category)}
                        helperText={form.errors.affiliation_category && form.touched.affiliation_category && String(form.errors.affiliation_category)}
                        fullWidth
                        style={{display: equalsIgnoreCase(form.values.affiliation_type, 'Faculty & Staff') ? 'block' : 'none'}}
                        InputLabelProps={{required: false, htmlFor: 'affiliation_category'}}
                        inputProps={{id: 'affiliation_category'}}
                        select
                        {...field}
                      >
                        <MenuItem value=''> -- Select an affiliation category -- </MenuItem>
                        {getSunyDmcAffiliationCategories().map((d) => (
                          <MenuItem value={d} key={d}>
                            {d}
                          </MenuItem>
                        ))}
                      </TextField>
                    </>
                  )}
                </Field>
                <Field className='form-form__form__affiliation-other-category' name='affiliation_other_category'>
                  {({field, form, meta}) => (
                    <>
                      <TextField
                        label='Other affiliation category'
                        name='affiliation_other_category'
                        size={'small'}
                        error={Boolean(form.errors.affiliation_other_category && form.touched.affiliation_other_category)}
                        helperText={form.errors.affiliation_other_category && form.touched.affiliation_other_category && String(form.errors.affiliation_other_category)}
                        fullWidth
                        style={{display: equalsIgnoreCase(form.values.affiliation_type, 'faculty & staff') && equalsIgnoreCase(form.values.affiliation_category, 'other') ? 'block' : 'none'}}
                        InputLabelProps={{required: false, htmlFor: 'affiliation_category'}}
                        inputProps={{id: 'affiliation_other_category'}}
                        {...field}
                      />
                    </>
                  )}
                </Field>
                <Field className='form-form__form__entity' name='entity'>
                  {({field, form, meta}) => (
                    <>
                      <TextField
                        label='Entity'
                        name='entity'
                        size={'small'}
                        error={Boolean(form.errors.entity && form.touched.entity)}
                        helperText={form.errors.entity && form.touched.entity && String(form.errors.entity)}
                        fullWidth
                        //style={{display: equalsIgnoreCase(form.values.affiliation_type, 'faculty & staff') && equalsIgnoreCase(form.values.affiliation_category, 'state') ? "block" : "none"}}
                        style={{display: 'none'}} //replace with above in case when needed
                        InputLabelProps={{required: false, htmlFor: 'entity'}}
                        inputProps={{id: 'entity'}}
                        select
                        {...field}
                      >
                        <MenuItem value=''> -- Select entity -- </MenuItem>
                        {getSunyDmcStateEntities().map((d) => (
                          <MenuItem value={d} key={d}>
                            {d}
                          </MenuItem>
                        ))}
                      </TextField>
                    </>
                  )}
                </Field>
                <Field className='form-form__form__nys-employee-d' name='nys_employee_id'>
                  {({field, form, meta}) => (
                    <>
                      <TextField
                        label='NYS Employee ID'
                        name='nys_employee_id'
                        size={'small'}
                        error={Boolean(form.errors.nys_employee_id && form.touched.nys_employee_id)}
                        helperText={form.errors.nys_employee_id && form.touched.nys_employee_id && String(form.errors.nys_employee_id)}
                        fullWidth
                        style={{display: equalsIgnoreCase(form.values.affiliation_type, 'faculty & staff') && equalsIgnoreCase(form.values.affiliation_category, 'state') ? 'block' : 'none'}}
                        inputProps={{maxLength: 9, id: 'nys_employee_id'}}
                        InputLabelProps={{required: false, htmlFor: 'nys_employee_id'}}
                        {...field}
                      />
                      <p
                        className={'hint'}
                        style={{display: equalsIgnoreCase(form.values.affiliation_type, 'faculty & staff') && equalsIgnoreCase(form.values.affiliation_category, 'state') ? 'block' : 'none'}}
                      >
                        Check NYS EMPLID on your Payroll. <a onClick={() => setPayrollExampleOpen(true)}>Show
                        example</a>.
                      </p>
                    </>
                  )}
                </Field>
                <Field className='form-form__form__union' name='union'>
                  {({field, form, meta}) => (
                    <>
                      <TextField
                        label='Union'
                        name='union'
                        size={'small'}
                        error={Boolean(form.errors.union && form.touched.union)}
                        helperText={form.errors.union && form.touched.union && String(form.errors.union)}
                        fullWidth
                        //style={{display: equalsIgnoreCase(form.values.affiliation_type, 'faculty & staff') && equalsIgnoreCase(form.values.affiliation_category, 'state') ? "block" : "none"}}
                        style={{display: 'none'}} //replace with above in case when needed
                        InputLabelProps={{required: false, htmlFor: 'union'}}
                        inputProps={{id: 'union'}}
                        select
                        {...field}
                      >
                        <MenuItem value=''> -- Select union -- </MenuItem>
                        {getSunyDmcStateUnion().map((d) => (
                          <MenuItem value={d} key={d}>
                            {d}
                          </MenuItem>
                        ))}
                      </TextField>
                    </>
                  )}
                </Field>
                <Field className='form-form__form__other-union' name='other_union'>
                  {({field, form, meta}) => (
                    <>
                      <TextField
                        label='Other union'
                        name='other_union'
                        size={'small'}
                        error={Boolean(form.errors.other_union && form.touched.other_union)}
                        helperText={form.errors.other_union && form.touched.other_union && String(form.errors.other_union)}
                        fullWidth
                        //style={{display: equalsIgnoreCase(form.values.affiliation_type, 'faculty & staff') && equalsIgnoreCase(form.values.affiliation_category, 'state') && equalsIgnoreCase(form.values.union, "other") ? "block" : "none"}}
                        style={{display: 'none'}} //replace with above in case when needed
                        InputLabelProps={{required: false, htmlFor: 'other_union'}}
                        inputProps={{id: 'other_union'}}
                        {...field}
                      />
                    </>
                  )}
                </Field>
                <Field className='form-form__form__handicapped-placard' name='handicapped_placard'>
                  {({field, form, meta}) => (
                    <>
                      <TextField
                        label='Handicapped placard #'
                        name={'handicapped_placard'}
                        size={'small'}
                        error={Boolean(form.errors.handicapped_placard && form.touched.handicapped_placard)}
                        helperText={form.errors.handicapped_placard && form.touched.handicapped_placard && String(form.errors.handicapped_placard)}
                        fullWidth
                        InputLabelProps={{required: false, htmlFor: 'handicapped_placard'}}
                        inputProps={{maxLength: 7, id: 'handicapped_placard'}}
                        InputProps={{
                          id: 'handicapped_placard',
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Accessible/>
                            </InputAdornment>
                          ),
                        }}
                        {...field}
                      />
                    </>
                  )}
                </Field>
              </>
            )}
            <VehiclePart vehicle_number={1} close={handleRemoveVehicleClick}/>
            <VehiclePart vehicle_number={2} display={numberOfCars >= 2} close={handleRemoveVehicleClick}/>
            <VehiclePart vehicle_number={3} display={numberOfCars >= 3} close={handleRemoveVehicleClick}/>
            <Button className='form__form__next-vehicle' onClick={handleNextVehicleClick} variant='text'>
              + Add next vehicle
            </Button>
            <br/>
            {data.company_name === 'suny' && (
              <>
                <Field className='form-form__form__accept-toc' name='accept_toc'>
                  {({field, form, meta}) => (
                    <>
                      <FormControlLabel
                        className={'accept-toc-wrapper'}
                        control={<Checkbox name='accept_toc' size='small' disabled={acceptedToc === true} {...field} />}
                        label={
                          <div className={'accept-toc-label'}>
                            Agree to&nbsp;
                            <a href={data.tocUrl}>Terms and Conditions</a>
                          </div>
                        }
                      />
                    </>
                  )}
                </Field>
                <p className={'accept-toc-error'} hidden={acceptedToc !== false}>
                  You must agree before submitting.
                </p>
              </>
            )}
            <br/>
            <Button className='form__form__submit' onClick={handleSubmitClick} variant='contained'>
              Signup
            </Button>
          </Form>
        </Formik>
      </div>
      {data.company_name === 'suny' && (
        <>
          <Dialog open={downstateIdExampleOpen} onClose={() => setDownstateIdExampleOpen(false)}>
            <DialogTitle>Donwstate Id Card #</DialogTitle>
            <Box>
              <img style={{width: '100%'}} src={DownstateCardExample} alt={'downstate id card example'}/>
            </Box>
          </Dialog>
          <Dialog open={payrollExampleOpen} onClose={() => setPayrollExampleOpen(false)}>
            <DialogTitle>Payroll Employee Id #</DialogTitle>
            <Box>
              <img style={{width: '100%'}} src={PayrollExample} alt={'payroll employee id example'}/>
            </Box>
          </Dialog>
        </>
      )}
      <p className='col-12 mt-5'>
        More info available on Parking website
        <br/>
        <a href={data.webPageFullUrl} target='_blank' rel='noreferrer'>
          {data.webPage}
        </a>
        <br/>
        Questions? eMail&nbsp;
        <a href={data.emailHref} target='_blank' rel='noreferrer'>
          {data.email}
        </a>
        &nbsp;or call&nbsp;
        <a href={`tel:${data.phoneNumber}`} target='_blank' rel='noreferrer'>
          {data.phoneNumberParsed}
        </a>
      </p>
    </div>
  );
};
export default SignupForm;
