import React from "react";
import "./index.css";
//import reportWebVitals from './reportWebVitals';
import AppRouter from "./AppRouter";

const createRoot = () => {
	return import("react-dom/client").then(({ createRoot }) => {
		return createRoot(document.getElementById("root"));
	});
};

createRoot().then((root) => {
	root.render(<AppRouter />);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
