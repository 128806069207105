import React, {useState} from 'react';
import './Signup.scss';
import ConfirmationMessage from '../message/confirmation/ConfirmationMessage';
import SignupForm from './SignupForm';

const MonthlySignup = ({displayMessage, data}) => {
  console.log('🚀 ~ MonthlySignup ~ data:', data);
  const [signupResult, setSignupResult] = useState();

  const getComponentToDisplay = () => {
    if (signupResult == null) {
      return <SignupForm data={data} setSignupResult={setSignupResult} displayMessage={displayMessage}/>;
    } else {
      const messageBody =
        signupResult === 'mail_error'
          ? `Your request has been successfully sent.
				 
				 We couldn't send you email. If you don't receive further information soon, please contact us.`
          : `Your request has been successfully sent.
				
				You will receive further information via e-mail.`;
      return <ConfirmationMessage messageHeader={'Success!'} messageBody={messageBody} status={'ok'}/>;
    }
  };

  return <div className='signup'>{getComponentToDisplay()}</div>;
};

export default MonthlySignup;
